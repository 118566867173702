import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  Form,
  Button,
  Alert,
} from "react-bootstrap";
import Select from "react-select";
import api from "../../../../utils/ApiMethod";
import { AppDispatch, RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { logoutUser } from "../../../../redux/actions";
import { ADMIN_LOGIN_BASE_URL } from "../../../../apiservices/apiService";

interface InputField {
  value: string;
  isValid: boolean;
}
interface StudentFormData {
  Udise_Code: string;
  studentId: string;
  selectedInTeam: string;
  studentName: InputField;
  gender: InputField;
  aadharNumber: InputField;
  fatherName: InputField;
  motherName: InputField;
  dob: InputField;
  caldob: InputField;
  ageCategory: "U-14 (Boys)" | "U-14 (Girls)" | "U-16 (Boys)" | "U-16 (Girls)";
  mobileNumber: InputField;
  email: InputField;
  accountNumber: string;
  level: string[];
  gameName: string[];
  height: InputField;
  weight: InputField;
  thirtyMFlingStarts: InputField;
  standingBroadJump: InputField;
  shuttleRun10MX6: InputField;
  verticalJump: InputField;
  footballBallThrow5No: InputField;
  eightHundredMetersRun: InputField;
  accountHolderName: string;
  ifscCode: string;
  bankName: string;
  bankBranchName: string;
  updatable?: Boolean;
  _id?: string;
}

const gameOptions = {
  "U-14": [
    "Football",
    "Kabaddi",
    "Cycling",
    "Longjump (Athletics)",
    "cricketBallThrow (Athletics)",
    "60m (run) (Athletics)",
    "600m (run) (Athletics)",
  ],
  "U-16": [
    "Football",
    "Kabaddi",
    "Cycling",
    "Longjump (Athletics)",
    "cricketBallThrow (Athletics)",
    "100m (run) (Athletics)",
    "800m (run) (Athletics)",
  ],
};

const ProfileStudentRegistrationForm: React.FC = () => {
  const [formData, setFormData] = useState<StudentFormData>({
    Udise_Code: "",
    studentId: "",
    selectedInTeam: "",
    studentName: { value: "", isValid: true },
    gender: { value: "Female", isValid: true },
    aadharNumber: { value: "", isValid: true },
    fatherName: { value: "", isValid: true },
    motherName: { value: "", isValid: true },
    dob: { value: "", isValid: true },
    caldob: { value: "", isValid: true },
    ageCategory: "U-14 (Boys)",
    mobileNumber: { value: "", isValid: true },
    email: { value: "", isValid: true },
    accountNumber: "",
    level: [],
    gameName: [],
    height: { value: "", isValid: true },
    weight: { value: "", isValid: true },
    thirtyMFlingStarts: { value: "", isValid: true },
    standingBroadJump: { value: "", isValid: true },
    shuttleRun10MX6: { value: "", isValid: true },
    verticalJump: { value: "", isValid: true },
    footballBallThrow5No: { value: "", isValid: true },
    eightHundredMetersRun: { value: "", isValid: true },
    accountHolderName: "",
    ifscCode: "",
    bankName: "",
    bankBranchName: "",
  });
  const [isError, setIsError] = useState<boolean>(false);
  const [isResponse, setIsResponse] = useState("");

  const { studentId } = useParams();
  const dobRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch<AppDispatch>();

  const { user } = useSelector((state: RootState) => ({
    user: state.Auth.user,
  }));

  console.log(formData.mobileNumber, "MOBILE");

  let profileId = user?._id ?? user?.user?._id ?? "";
  const Key_Key = user?.moniterd_ttl ?? user?.user?.moniterd_ttl ?? "";

  const formatDateString = (dateString: string) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const fetchStudentProfiles = async () => {
    try {
      const response = await api.fetchDataAuth(
        `api/v1/student-profile/block/${studentId}/${profileId}/${Key_Key}`
      );
      console.log("response?.data", response?.data);

      const fetchedData = response?.data?.data;

      setFormData({
        Udise_Code: fetchedData.Udise_Code,
        studentId: fetchedData.studentId,
        selectedInTeam: fetchedData.selectedInTeam,
        studentName: { value: fetchedData.studentName || "", isValid: true },
        gender: { value: fetchedData.gender || "", isValid: true },
        aadharNumber: { value: fetchedData.aadharNumber || "", isValid: true },
        fatherName: { value: fetchedData.fatherName || "", isValid: true },
        motherName: { value: fetchedData.motherName || "", isValid: true },
        updatable: fetchedData.updatable,
        _id: fetchedData._id,

        dob: {
          value: fetchedData.dob ? formatDateString(fetchedData.dob) : "",
          isValid: true,
        },
        caldob: { value: fetchedData.caldob || "", isValid: true },
        ageCategory: fetchedData.ageCategory,
        mobileNumber: { value: fetchedData.mobileNumber || "", isValid: true },
        email: { value: fetchedData.email || "", isValid: true },
        accountNumber: fetchedData.accountNumber,
        level: fetchedData.level,
        gameName: fetchedData.gameName,
        height: { value: fetchedData.height || "", isValid: true },
        weight: { value: fetchedData.weight || "", isValid: true },
        thirtyMFlingStarts: {
          value: fetchedData.thirtyMFlingStarts || "",
          isValid: true,
        },
        standingBroadJump: {
          value: fetchedData.standingBroadJump || "",
          isValid: true,
        },
        shuttleRun10MX6: {
          value: fetchedData.shuttleRun10MX6 || "",
          isValid: true,
        },
        verticalJump: { value: fetchedData.verticalJump || "", isValid: true },
        footballBallThrow5No: {
          value: fetchedData.footballBallThrow5No || "",
          isValid: true,
        },
        eightHundredMetersRun: {
          value: fetchedData.eightHundredMetersRun || "",
          isValid: true,
        },
        accountHolderName: fetchedData.accountHolderName,
        ifscCode: fetchedData.ifscCode,
        bankName: fetchedData.bankName,
        bankBranchName: fetchedData.bankBranchName,
      });
    } catch (error) {
      console.error("Error fetching student profile:", error);
    }
  };
  // console.log('student  id', formData?._id, 'jvhui', formData.studentId)

  useEffect(() => {
    fetchStudentProfiles();
  }, [studentId]);

  console.log(formData.studentId, "STDENT ID");

  // const handleChange = (e: React.ChangeEvent<any>) => {
  //   const { name, value } = e.target;
  //   const validNumberRegex = /^\d*\.?\d{0,2}$/;
  //   const isValid = validNumberRegex.test(value);

  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: { value, isValid },
  //   }));
  // };

  const handleChange = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.target;
    let validRegex: RegExp;

    // Define regex and validation logic based on the field name
    switch (name) {
      case "height":
        validRegex = /^\d{0,3}$/; // Allow up to 3 digits for height
        break;
      case "eightHundredMetersRun":
        validRegex = /^([0-5]?[0-9]):([0-5]?[0-9]):([0-9]{1,3})$/; // Time format MM:SS:SSS for eightHundredMetersRun
        break;
      default:
        validRegex = /^\d{0,3}(\.\d{0,2})?$/; // Default regex for other fields (up to 3 digits and up to 2 decimal places)
    }

    let formattedValue = value;
    if (name === "eightHundredMetersRun") {
      formattedValue = formatTime(value); // Format time for eightHundredMetersRun field
    }

    const isValid = validRegex.test(formattedValue);

    setFormData((prevData) => ({
      ...prevData,
      [name]: { value: formattedValue, isValid },
    }));
  };

  const formatTime = (value: string) => {
    const cleaned = value.replace(/\D+/g, ""); // Remove all non-numeric characters
    const match = cleaned.match(/^(\d{0,2})(\d{0,2})(\d{0,3})$/);
    if (match) {
      return [match[1], match[2], match[3]].filter(Boolean).join(":");
    }
    return value;
  };

  console.log(formData, "on handle change");

  const getInputStyle = (isValid: boolean) => ({
    borderColor: isValid ? "green" : "red",
  });
  const handleGameChange = (selectedOptions: any) => {
    const selectedGames = selectedOptions
      ? selectedOptions.map((option: any) => option.value)
      : [];
    setFormData((prevData) => ({
      ...prevData,
      gameName: selectedGames,
    }));
  };

  const getAvailableGames = () => {
    const ageGroup = formData?.ageCategory?.startsWith("U-14")
      ? "U-14"
      : "U-16";
    const allGames = gameOptions[ageGroup];

    const selectedGames = formData?.gameName;
    const athleticsGamesSelected = selectedGames?.filter((game) =>
      game?.includes("(Athletics)")
    ).length;

    const isOtherGameSelected = selectedGames.some(
      (game) => !game.includes("(Athletics)")
    );

    return allGames.map((game) => ({
      value: game,
      label: game,
      isDisabled:
        isOtherGameSelected ||
        (athleticsGamesSelected >= 2 && game.includes("(Athletics)")) ||
        (athleticsGamesSelected > 0 && !game.includes("(Athletics)")),
    }));
  };

  const availableGames = getAvailableGames();

  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      maxHeight: 200,
      overflowY: "auto",
    }),
  };

  useEffect(() => {
    const dobInput = dobRef.current;
    const handleFocus = () => {
      if (
        dobInput instanceof HTMLInputElement &&
        typeof dobInput.focus === "function"
      ) {
        dobInput.focus();
      }
    };

    if (dobInput) {
      dobInput.addEventListener("focus", handleFocus);
    }

    return () => {
      if (dobInput) {
        dobInput.removeEventListener("focus", handleFocus);
      }
    };
  }, []);

  const updateStudentProfile = async (studentId: string, action: string) => {

    setIsError(false);

    const invalidFields = Object.entries(formData).filter(
      ([key, value]) => typeof value === "object" && !value.isValid
    );
    console.log(invalidFields, "INPUT");

    // if (invalidFields.length > 0) {
    //   setIsError(true);
    //   return;
    // }

    // const payload = {
    //   studentId: formData.studentId,
    //   selectedInTeam: formData.selectedInTeam,
    //   studentName: formData.studentName.value,
    //   gender: formData.gender.value,
    //   aadharNumber: formData.aadharNumber.value,
    //   fatherName: formData.fatherName.value,
    //   motherName: formData.motherName.value,
    //   dob: formData.dob.value,
    //   caldob: formData.caldob.value,
    //   ageCategory: formData.ageCategory,
    //   mobileNumer: formData.mobileNumber.value,
    //   email: formData.email.value,
    //   accountNumber: formData.accountNumber,
    //   level: formData.level,
    //   gameName: formData.gameName,
    //   height: formData.height.value,
    //   weight: formData.weight.value,
    //   thirtyMFlingStarts: formData.thirtyMFlingStarts.value,
    //   standingBroadJump: formData.standingBroadJump.value,
    //   shuttleRun10MX6: formData.shuttleRun10MX6.value,
    //   verticalJump: formData.verticalJump.value,
    //   footballBallThrow5No: formData.footballBallThrow5No.value,
    //   eightHundredMetersRun: formData.eightHundredMetersRun.value,
    //   accountHolderName: formData.accountHolderName,
    //   ifscCode: formData.ifscCode,
    //   bankName: formData.bankName,
    //   bankBranchName: formData.bankBranchName,
    //   Udise_Code: formData.Udise_Code,
    // };

    const formDataToSend = new FormData();

    // Append each piece of state data to the FormData object
    formDataToSend.append("studentId", formData.studentId);
    formDataToSend.append("selectedInTeam", formData.selectedInTeam);
    formDataToSend.append("studentName", formData.studentName.value);
    formDataToSend.append("gender", formData.gender.value);
    formDataToSend.append("aadharNumber", formData.aadharNumber.value);
    formDataToSend.append("fatherName", formData.fatherName.value);
    formDataToSend.append("motherName", formData.motherName.value);
    formDataToSend.append("dob", formData.dob.value);
    formDataToSend.append("caldob", formData.caldob.value);
    formDataToSend.append("ageCategory", formData.ageCategory);
    formDataToSend.append("mobileNumber", formData.mobileNumber.value);
    formDataToSend.append("email", formData.email.value);
    formDataToSend.append("accountNumber", formData.accountNumber);
    formData.level.forEach((level, index) => {
      formDataToSend.append(`level[${index}]`, level);
    });
    formData.gameName.forEach((game, index) => {
      formDataToSend.append(`gameName[${index}]`, game);
    });
    formDataToSend.append("height", formData.height.value);
    formDataToSend.append("weight", formData.weight.value);
    formDataToSend.append(
      "thirtyMFlingStarts",
      formData.thirtyMFlingStarts.value
    );
    formDataToSend.append(
      "standingBroadJump",
      formData.standingBroadJump.value
    );
    formDataToSend.append("shuttleRun10MX6", formData.shuttleRun10MX6.value);
    formDataToSend.append("verticalJump", formData.verticalJump.value);
    formDataToSend.append(
      "footballBallThrow5No",
      formData.footballBallThrow5No.value
    );
    formDataToSend.append(
      "eightHundredMetersRun",
      formData.eightHundredMetersRun.value
    );
    if (
      formData.shuttleRun10MX6.value === "" ||
      formData.thirtyMFlingStarts.value === "" ||
      formData.verticalJump.value === "" ||
      formData.standingBroadJump.value === "" ||
      formData.weight.value === "" ||
      formData.height.value === ""
    ) {
      setIsError(true);
      let errorMessage = "Please Enter Every the battery test Data";
      setIsResponse(errorMessage);
      return;
    }
    formDataToSend.append("accountHolderName", formData.accountHolderName);
    formDataToSend.append("ifscCode", formData.ifscCode);
    formDataToSend.append("bankName", formData.bankName);
    formDataToSend.append("bankBranchName", formData.bankBranchName);
    formDataToSend.append("Udise_Code", formData.Udise_Code);
    console.log(formDataToSend, "PAYLOAD");

      // Confirmation alert before making the API call
  const isConfirmed = window.confirm("Please review all your details carefully before proceeding. Once you submit, you will not be able to make any further edits.");
  if (!isConfirmed) {
    return;
  }


    try {
      const response = await api.updatePatchData(
        `/update/student/register/${profileId}/${studentId}/${action}/${Key_Key}`,
        formDataToSend
      );
      if (response.status === 440) {
        alert("Session Expired");
        dispatch(logoutUser());
        window.location.href = ADMIN_LOGIN_BASE_URL; // Use the full URL, including the protocol (http or https)
      }
      if (response.status === 400) {
        setIsError(true);
        let errorMessage = await response.message;
        setIsResponse(errorMessage);
      }
      if (!response.status === true) {
        // console.log("response--", await response.json())
        let errorMsg = await response.json();
        setIsResponse(errorMsg.message);
        // console.log("response from update user---",response)
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      if (response.status === 200 || response.status === 201) {
        alert("Your Record Submitted Successfully");
        window.location.reload();
      }
      setIsResponse(response.message);
    } catch (error) {
      console.error("Error updating student profile:", error);
      setIsError(true);
    }
  };

  return (
    <Container>
      <Card className="my-4">
        <Card.Body>
          <h2
            className="header-title mt-4 mb-4 p-2 text-center fs-19"
            style={{ border: "2px solid grey", borderRadius: "5px" }}
          >
            Student Profile Details
          </h2>
          <Form>
            <Form.Group as={Row} className="mb-3" controlId="formStudentName">
              <Form.Label column md={4} className="text-md-start">
                Student's Full Name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col md={8}>
                <Form.Control
                  type="text"
                  name="studentName"
                  value={formData.studentName.value}
                  onChange={handleChange}
                  required
                  readOnly={!formData?.updatable} // Conditionally set readOnly based on updatable prop
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formGender">
              <Form.Label column md={4} className="text-md-start">
                Gender <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col md={8}>
                <div>
                  <Form.Check
                    inline
                    label="Girl"
                    name="gender"
                    type="radio"
                    id="genderFemale"
                    value="Female"
                    checked={formData.gender.value === "Female"}
                    onChange={handleChange}
                    disabled={!formData.updatable} // Conditionally disable based on updatable prop
                  />
                  <Form.Check
                    inline
                    label="Boy"
                    name="gender"
                    type="radio"
                    id="genderMale"
                    value="Male"
                    checked={formData.gender.value === "Male"}
                    onChange={handleChange}
                    disabled={!formData.updatable} // Conditionally disable based on updatable prop
                  />
                </div>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formAadharNumber">
              <Form.Label column md={4} className="text-md-start">
                Aadhar Number <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col md={8}>
                <Form.Control
                  type="text"
                  name="aadharNumber"
                  value={formData.aadharNumber.value}
                  onChange={handleChange}
                  maxLength={12}
                  required
                  readOnly={!formData?.updatable} // Conditionally set readOnly based on updatable prop
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formFatherName">
              <Form.Label column md={4} className="text-md-start">
                Father's Full Name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col md={8}>
                <Form.Control
                  type="text"
                  name="fatherName"
                  value={formData.fatherName.value}
                  onChange={handleChange}
                  required
                  readOnly={!formData?.updatable} // Conditionally set readOnly based on updatable prop
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formMotherName">
              <Form.Label column md={4} className="text-md-start">
                Mother's Full Name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col md={8}>
                <Form.Control
                  type="text"
                  name="motherName"
                  value={formData.motherName.value}
                  onChange={handleChange}
                  required
                  readOnly={!formData?.updatable} // Conditionally set readOnly based on updatable prop
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formDOB">
              <Form.Label column md={4} className="text-md-start">
                Date of Birth <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col md={8}>
                <Form.Control
                  type="date"
                  name="dob"
                  value={formData.dob.value}
                  onChange={handleChange}
                  required
                  ref={dobRef}
                  readOnly={!formData?.updatable} // Conditionally set readOnly based on updatable prop
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formAgeAsOn">
              <Form.Label column md={4} className="text-md-start">
                Age as on 31st Dec 2023
              </Form.Label>
              <Col md={8}>
                <Form.Control
                  type="text"
                  name="caldob"
                  value={formData?.caldob.value}
                  readOnly
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formCategory">
              <Form.Label column md={4} className="text-md-start">
                Category
              </Form.Label>
              <Col md={8}>
                <Form.Control
                  type="text"
                  name="ageCategory"
                  value={formData?.ageCategory}
                  readOnly
                  style={{ backgroundColor: "#e9ecef" }}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formMobileNumber">
              <Form.Label column md={4} className="text-md-start">
                Parent/Guardian Mobile Number{" "}
                <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col md={8}>
                <Form.Control
                  type="text"
                  name="mobileNumber"
                  value={formData?.mobileNumber?.value}
                  onChange={handleChange}
                  maxLength={10}
                  required
                  readOnly={!formData?.updatable} // Conditionally set readOnly based on updatable prop
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formEmail">
              <Form.Label column md={4} className="text-md-start">
                E-Mail Id
              </Form.Label>
              <Col md={8}>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email.value}
                  onChange={handleChange}
                  readOnly={!formData?.updatable} // Conditionally set readOnly based on updatable prop
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formGameName">
              <Form.Label column md={4} className="text-md-start">
                Select Your Game <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col md={8}>
                <Select
                  isMulti
                  name="gameName"
                  options={availableGames}
                  value={availableGames.filter((game) =>
                    formData.gameName.includes(game.value)
                  )}
                  onChange={handleGameChange}
                  closeMenuOnSelect={false}
                  styles={customStyles}
                  isDisabled={!formData?.updatable} // Conditionally disable based on updatable prop
                />
                <small className="form-text text-muted">
                  In athletics, a maximum of two events can be selected.
                </small>
              </Col>
            </Form.Group>
            <h2
              className="header-title mt-4 mb-4 p-2 text-center fs-19"
              style={{ border: "2px solid grey", borderRadius: "5px" }}
            >
              Battery Test Details
            </h2>

            <Row className="mb-3">
              <Col md={3}>
                <Form.Group controlId="formHeight">
                  <Form.Label>Height (in cm)</Form.Label>
                  <Form.Control
                    type="text"
                    name="height"
                    value={formData.height.value}
                    onChange={handleChange}
                    style={getInputStyle(formData.height.isValid)}
                    readOnly={!formData?.updatable} // Conditionally set readOnly based on updatable prop
                    required={!formData?.updatable}
                  />
                  {!formData.height.isValid && (
                    <p style={{ color: "red" }}>
                      Invalid input. Please enter a valid number.
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="formWeight">
                  <Form.Label>Weight (in Kg)</Form.Label>
                  <Form.Control
                    type="text"
                    name="weight"
                    value={formData.weight.value}
                    onChange={handleChange}
                    style={getInputStyle(formData.weight.isValid)}
                    readOnly={!formData?.updatable} // Conditionally set readOnly based on updatable prop
                    required={!formData?.updatable}
                  />
                  {!formData.weight.isValid && (
                    <p style={{ color: "red" }}>
                      Invalid input. Please enter a valid number.
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="formVerticalJump">
                  <Form.Label>Vertical Jump (in cm)</Form.Label>
                  <Form.Control
                    type="text"
                    name="verticalJump"
                    value={formData.verticalJump.value}
                    onChange={handleChange}
                    style={getInputStyle(formData.verticalJump.isValid)}
                    readOnly={!formData?.updatable} // Conditionally set readOnly based on updatable prop
                  />
                  {!formData.verticalJump.isValid && (
                    <p style={{ color: "red" }}>
                      Invalid input. Please enter a valid number.
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="formThirtyMFlingStarts">
                  <Form.Label>30 Mtr sprint(in sec.)</Form.Label>
                  <Form.Control
                    type="text"
                    name="thirtyMFlingStarts"
                    value={formData.thirtyMFlingStarts.value}
                    onChange={handleChange}
                    style={getInputStyle(formData.thirtyMFlingStarts.isValid)}
                    readOnly={!formData?.updatable} // Conditionally set readOnly based on updatable prop
                  />
                  {!formData.thirtyMFlingStarts.isValid && (
                    <p style={{ color: "red" }}>
                      Invalid input. Please enter a valid number.
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={3}>
                <Form.Group controlId="formFootballBallThrow5No">
                  <Form.Label>Football Throw ( in meter)</Form.Label>
                  <Form.Control
                    type="text"
                    name="footballBallThrow5No"
                    value={formData.footballBallThrow5No.value}
                    onChange={handleChange}
                    required={true}
                    style={getInputStyle(formData.footballBallThrow5No.isValid)}
                    readOnly={!formData?.updatable} // Conditionally set readOnly based on updatable prop
                  />
                  {!formData.footballBallThrow5No.isValid && (
                    <p style={{ color: "red" }}>
                      Invalid input. Please enter a valid number.
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="formShuttleRun10MX6">
                  <Form.Label>6X10 Mtr shuttle run (in second)</Form.Label>
                  <Form.Control
                    type="text"
                    name="shuttleRun10MX6"
                    value={formData.shuttleRun10MX6.value}
                    onChange={handleChange}
                    required
                    style={getInputStyle(formData.shuttleRun10MX6.isValid)}
                    readOnly={!formData?.updatable} // Conditionally set readOnly based on updatable prop
                  />
                  {!formData.shuttleRun10MX6.isValid && (
                    <p style={{ color: "red" }}>
                      Invalid input. Please enter a valid number.
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="formStandingBroadJump">
                  <Form.Label>Standing Broad jump(in meter)</Form.Label>
                  <Form.Control
                    type="text"
                    name="standingBroadJump"
                    value={formData.standingBroadJump.value}
                    onChange={handleChange}
                    required
                    style={getInputStyle(formData.standingBroadJump.isValid)}
                    readOnly={!formData?.updatable} // Conditionally set readOnly based on updatable prop
                  />
                  {!formData.standingBroadJump.isValid && (
                    <p style={{ color: "red" }}>
                      Invalid input. Please enter a valid number.
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="formEightHundredMetersRun">
                  <Form.Label>
                    800 mts Run ( in mm:ss:msms) {formData?.updatable}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="eightHundredMetersRun"
                    value={formData.eightHundredMetersRun.value}
                    onChange={handleChange}
                    pattern="^\d*\.?\d{0,2}$"
                    style={getInputStyle(
                      formData.eightHundredMetersRun.isValid
                    )}
                    placeholder="MM:SS:SSS"
                    required={true}
                    readOnly={!formData?.updatable} // Conditionally set readOnly based on updatable prop
                  />
                  {!formData.eightHundredMetersRun.isValid && (
                    <p style={{ color: "red" }}>
                      Invalid input. Please enter a valid number.
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            {isError && (
              <Alert variant="danger" className="my-2">
                {isResponse}
              </Alert>
            )}

            {formData?.updatable === true && (
              <Row>
                <Col className="text-end">
                  <Button
                    onClick={() =>
                      updateStudentProfile(formData?._id ?? "", "submit")
                    }
                    className="btn btn-primary"
                  >
                    Final Submission
                  </Button>
                </Col>
              </Row>
            )}
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ProfileStudentRegistrationForm;
